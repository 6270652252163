import React from "react"

import ActionBtn from "../../../All/ActionBtn"
import CarCard from "./CarCard"


export function CarsWrapper({ props: { cars, Reducer } }) {

  return (
    <div className="CarsWrapper flex column start">

      <div className="CarsTopLine flex end">
        <ActionBtn props={{ name:`cancel`, click:()=>Reducer({type:"BACK_TO_BRANDS"}) }} />
      </div>

      <div className="Cards flex start stretch wrap">
        { cars.map( (car) => <CarCard props={{car}} key={`${car?._id}`} /> ) }
      </div>

    </div>
  )
}
