import React from "react"
import { Link } from 'react-router-dom'

import "./CarCard.scss"
import { brandIco } from "./../StoreSoftwareReducer"


function CarCard({ props: { car } }) {

  return (
    <div className="CarCard flex column start">
      <Link className="CarCardTop flex column start" to={`/softpage/${car?._id}`} target="_blank" rel="noopener noreferrer">

        <span className="CardLine BrandModel flex start">
          { car?.brand && <img className="ImgBtn IcoColor flex" src={brandIco(car?.brand)} alt="SVG" /> }
          <span className="Value overflow flex start bold">{`${car?.brand} - ${car?.model}`}</span>
        </span>

        <span className="CardLine ECUType flex start">
          <span className="Description flex start">ECU Type:</span>
          { car?.ECUType && <span className="Value overflow flex start">{`${car?.ECUType}`}</span> }
        </span>

        <span className="CardLine Engine flex start">
          <span className="Description flex start">Engine:</span>
          { car?.engine && <span className="Value overflow flex start">{`${car?.engine}`}</span> }
        </span>

        <span className="CardLine SoftwareVersion flex start">
          <span className="Description flex start">SW Version:</span>
          { car?.swVersion && <span className="Value overflow flex start">{`${car?.swVersion}`}</span> }
        </span>
          
        <span className="HardwareVersion CardLine flex start">
          <span className="Description flex start">HW Version:</span>
          { car?.hwVersion && <span className="Value overflow flex start">{`${car?.hwVersion}`}</span> }
        </span>

        <span className="CardLine VIN flex start">
          <span className="Description flex start">VIN:</span>
          { car?.VIN && <span className="Value overflow flex start">{`${car?.VIN}`}</span> }
        </span>

        <span className="CardLine Programmer flex start">
          <span className="Description flex start">Programmer:</span>
          { car?.programmer && <span className="Value overflow flex start">{`${car?.programmer}`}</span> }
        </span>

        <span className="CardLine SoftwareType flex start">
          <span className="Description flex start">Software Type:</span>
          { car?.swType && <span className="Value overflow flex start">{`${car?.swType}`}</span> }
        </span>

        <span className="CardLine ReadMethod flex start">
          <span className="Description flex start">Read Method:</span>
          { car?.readMethod && <span className="Value overflow flex start">{`${car?.readMethod}`}</span> }
        </span>

        <span className="CardLine Modifications flex start">
          <span className="Description flex start">Modifications:</span>
          { car?.mod && <span className="Value overflow flex start">{car.mod}</span> }
        </span>
        
      </Link>

      <div className="PriceLine flex between stretch">

        <div className="CardID txtGry flex column end">
          <span>{`ID: ${car?._id}`}</span>
        </div>

        {
          car?.price &&
          <div className="Price flex bold">
            <span>{`${parseInt(car?.price).toFixed(2)} zł`}</span>
          </div>
        }

      </div>

    </div>
  )
}

export default CarCard