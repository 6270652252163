import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import "./SoftPage.scss"
import { tr } from '../../../../../AppTranslate'
import { GetUser } from '../../../../../AppFunctions'
import SiteIcon from "./../../../../All/SiteIcon"
import { modTypes, programmers, readMethods, StoreSoftwareReducer, swTypes } from './../StoreSoftwareReducer'
import { SoftPagePropses } from './SoftPagePropses'
import InputText from '../../../../All/InputText'
import InputSelect from '../../../../All/InputSelect'
import { CardTopLine } from './CardTopLine'
import { GroupInfo1 } from './GroupInfo1'
import { GroupInfo2 } from './GroupInfo2'
import { SoftwarePannel } from './SoftwarePannel'
import { ECUsticker } from './ECUsticker'
import { CardBtns } from './CardBtns'


function SoftPage() {

  const user = GetUser()
  const lang = user?.lang
  const isAdmin = user?.role === "admin"

  const { id } = useParams()
  const navigate = useNavigate()
  const [car, setCar] = useState(null)

  const [edit, setEdit] = useState(false)
  const [save, setSave] = useState(false)
  const [editErr, setEditErr] = useState({})

  const isPayed = car?.pay?.status?.statusCode === "SUCCESS"

  const {
    brand, model, ECUType, engine, swVersion, hwVersion, VIN,
    programmer, swType, readMethod, mod, price
  } = SoftPagePropses(
    tr, lang, car, setCar, editErr, setEditErr, setSave, programmers, swTypes, readMethods, modTypes
  )

  const lines1 = [
    {classes:"Brand", props:brand, input:<InputText props={ brand }/> },
    {classes:"Model", props:model, input:<InputText props={ model }/> },
    {classes:"ECUType", props:ECUType, input:<InputText props={ ECUType }/> },
    {classes:"Engine", props:engine, input:<InputText props={ engine }/> },
    {classes:"SoftwareVersion", props:swVersion, input:<InputText props={ swVersion }/> },
    {classes:"HardwareVersion", props:hwVersion, input:<InputText props={ hwVersion }/> },
    {classes:"VIN", props:VIN, input:<InputText props={ VIN }/> }
  ]

  const lines2 = [
    {classes:"Programmer", props:programmer, input:<InputSelect props={ programmer }/> },
    {classes:"SoftwareType", props:swType, input:<InputSelect props={ swType }/> },
    {classes:"ReadMethod", props:readMethod, input:<InputSelect props={ readMethod }/> },
    {classes:"Modifications", props:mod, input:<InputSelect props={ mod }/> },
  ]

  const Reducer = (action)=> StoreSoftwareReducer({action, navigate, setSave, setCar, setEdit, setEditErr})

  useEffect( ()=> { Reducer({type:"GET_CAR_CARD", id}) }, [id])

  // console.log("car", car)
  // console.log("user", user)

  return (
    <div className="SoftPage flex">

    {
      !car
      ?
      <div className="DownloadIcon flex"><SiteIcon props={{speed:4}} /></div>
      :
      <div className="Card flex stretch wrap">

        <CardTopLine props={{car, isAdmin, edit, save, Reducer}}/>

        <div className="GroupInfo flex column start">
          <ECUsticker props={{id, isAdmin, car, setCar, edit, setSave}}/>
        </div>

        <div className="GroupInfo flex column start">
          <GroupInfo1 props={{lines1, edit}}/>
        </div>
        
        <div className="GroupInfo flex column start">
          <GroupInfo2 props={{lines2, edit}}/>
        </div>

        <div className="GroupInfo flex column start">
          <div className="Information flex column start">
            <p>Jak kupować na bzDrive.com?</p>
            <ul>
              <li>Przeglądaj dostępne oprogramowanie i narzędzia.</li>
              <li>Zaznacz pola przy plikach, które chcesz zakupić.</li>
              <li>Wybierz preferowaną metodę płatności.</li>
              <li>Po potwierdzeniu transakcji, Twoje pliki będą gotowe do pobrania.</li>
              <li>Po udanym zakupie, pliki staną się aktywnymi linkami.</li>
              <li>Kliknij na linki, aby pobrać wybrane pliki.</li>
              <li>W razie potrzeby, możesz wygenerować dokument zakupu.</li>
            </ul>
          </div>
        </div>

        <div className="ArticlesPannel flex column start">

          <SoftwarePannel props={{id, car, edit, setCar, setSave}}/>

          { !isPayed && <CardBtns props={{id, lang, edit, price, Reducer}}/> }

        </div>
        
      </div>
    }

    </div>
  )
}

export default SoftPage
