import React from "react"

import { brandIco } from "./StoreSoftwareReducer"
import ActionBtn from "./../../../All/ActionBtn"


export function BrandsWrapper({ props:{isBrands, brands, isAdmin, Reducer} }){

  return (
    <div className="BrandsWrapper flex start wrap">

      {
        isAdmin &&
        <a className="Brand AddNew flex" href={`/softpage/new`} target="_blank" rel="noopener noreferrer" key={`newElement`} >
          <ActionBtn props={{ name:`plus`, click:()=>Reducer({type:"ADD_NEW_CAR"}) }} />
        </a>
      }

      {
        isBrands && brands?.map( (item, i)=>{

          const key = `Brand${i}`

          return(
            <div className="Brand flex start" onClick={()=>Reducer({type:"SELECT_BRAND", brand:item?.brand})} key={key}>
              { item?.brand && <img className="ImgBtn IcoColor flex" src={brandIco(item?.brand)} alt="SVG" /> }
              <div className="BrandName flex start">{item?.brand}</div>
              <div className="Count flex">{item?.count}</div>
            </div>
          )

        })
      }

    </div>
  )
}