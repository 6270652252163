import React from 'react'

import { FileUpload } from './FileUpload'


export function ECUsticker({ props:{id, isAdmin, car, setCar, edit, setSave} }) {

  const allowedMimeTypes = ["image/jpeg"]
  const link = 'https://bzdrive.com'
  const defaultFileAddr = `files/SoftwareStore/${id}`
  const defaultFileName = `ECUsticker_${Date.now()}.jpg`

  function SET_ECU_STICKER(res){
    setCar(prev=> ({ ...prev, ECUsticker: res?.data }))
    setSave(true)
  }

  return (
    <div className="ECUsticker flex column start">
    {
      car?.ECUsticker
      ?
      <img className="ECUstickerImg flex" src={`${link}/${defaultFileAddr}/${car?.ECUsticker?.name}`} alt="label" />
      :
      <React.Fragment>
        <span className="CardLine NoECUsticker flex bold">{`No ECU sticker`}</span>
        <span className="CardLine flex start bold">{`ECU: ${car?.ECUType ?? ""}`}</span>
        <span className="CardLine flex start bold">{`SW: ${car?.swVersion ?? ""}`}</span>
        <span className="CardLine flex start bold">{`HW: ${car?.hwVersion ?? ""}`}</span>
      </React.Fragment>
    }

    {
      isAdmin && edit &&
      <div className='ECUstickerDownload flex end'>
        <FileUpload props={{ defaultFileAddr, defaultFileName, allowedMimeTypes, cb:SET_ECU_STICKER}} />
      </div>
    }
    </div>
  )
}
