import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'

import "./Software.scss"
import { GetUser } from "../../../../AppFunctions"
import { StoreSoftwareReducer } from "./StoreSoftwareReducer"
import { BrandsWrapper } from "./BrandsWrapper"
import { ModelsWrapper } from "./ModelsWrapper"
import CarCard from "./CarCard"


function Software() {

  const user = GetUser()
  const lang = user?.lang
  const isAdmin = user?.role === "admin"

  const [initialState, setInitialState] = useState([])

  const [brands, setBrands] = useState(false)

  const [models, setModels] = useState(false)

  const [cars, setCars] = useState(false)

  const isBrands = ( (initialState?.length > 0) && brands && !models ) 

  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerPage = 4
  const goToPrevious = ()=> { setCurrentIndex((prevIndex) => Math.max(prevIndex - itemsPerPage, 0)) }
  const goToNext = () => { setCurrentIndex((prevIndex) => Math.min(prevIndex + itemsPerPage, initialState.length - itemsPerPage)) }
  const currentCars = initialState.slice(currentIndex, currentIndex + itemsPerPage)

  const Reducer = (action)=> StoreSoftwareReducer({action, initialState, setInitialState, setBrands, setModels, setCars})

  useEffect( ()=> { Reducer({type:"GET_CAR_CARDS"}) }, [])

  // console.log("initialState",initialState)
  // console.log("brands",brands)
  // console.log("models",models)
  // console.log("cars",cars)

  return (
    <div className="Software flex wrap">

      <BrandsWrapper props={{isBrands, brands, isAdmin, Reducer}}/>

      { models && <ModelsWrapper props={{models, cars, Reducer}}/> }

      <div className="LatestSoftwareList flex wrap">

        <div className="LatestSoftwareTop flex start">{`LatestSoftwareList`}</div>

        <div className="LatestSoftwareSlider flex">

          <button onClick={goToPrevious} disabled={currentIndex === 0}>Previous</button>
          
          <div className="SliderItems flex stretch">
            { currentCars.map( (car, c)=> <CarCard props={{car}} key={`LatestSoftware${car?._id}${c}`} /> ) }
          </div>
          
          <button onClick={goToNext} disabled={currentIndex + itemsPerPage >= initialState.length}>Next</button>

        </div>

      </div>

    </div>
  )
}

export default Software