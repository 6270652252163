import { sanitizeTxt } from "../../../../../AppFunctions"


export const SoftPagePropses = (
  tr, lang, car, setCar, editErr, setEditErr, setSave, programmers, swTypes, readMethods, modTypes
)=> ({
  brand:{
    classes:"brand",
    // legend: tr(`BrandLegend`,lang),
    legend: "Brand",
    type: `text`,
    val: car?.brand ? sanitizeTxt(car.brand, `default`).sanText : '',
    err: editErr?.carBrand ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, brand:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, carBrand:sanitizeTxt(val, `default`).sanErr}))
  },
  model:{
    classes:"model",
    // legend: tr(`ModelLegend`,lang),
    legend: "Model",
    type: `text`,
    val: car?.model ? sanitizeTxt(car.model, `default`).sanText : '',
    err: editErr?.carModel ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, model:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, carModel:sanitizeTxt(val, `default`).sanErr}))
  },
  ECUType:{
    classes:"ECU Type",
    legend: "ECU Type",
    type: `text`,
    val: car?.ECUType ? sanitizeTxt(car.ECUType, `default`).sanText : '',
    err: editErr?.ECUType ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, ECUType:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, ECUType:sanitizeTxt(val, `default`).sanErr}))
  },
  engine:{
    classes:"engine",
    // legend: tr(`EngineLegend`,lang),
    legend: "Engine",
    type: `text`,
    val: car?.engine ? sanitizeTxt(car.engine, `default`).sanText : '',
    err: editErr?.carEngine ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, engine:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, carEngine:sanitizeTxt(val, `default`).sanErr}))
  },
  swVersion:{
    classes:"swVersion",
    // legend: tr(`EngineLegend`,lang),
    legend: "SW Version",
    type: `text`,
    val: car?.swVersion ? sanitizeTxt(car.swVersion, `default`).sanText : '',
    err: editErr?.swVersion ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, swVersion:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, swVersion:sanitizeTxt(val, `default`).sanErr}))
  },
  hwVersion:{
    classes:"hwVersion",
    // legend: tr(`EngineLegend`,lang),
    legend: "HW Version",
    type: `text`,
    val: car?.hwVersion ? sanitizeTxt(car.hwVersion, `default`).sanText : '',
    err: editErr?.hwVersion ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, hwVersion:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, hwVersion:sanitizeTxt(val, `default`).sanErr}))
  },
  VIN:{
    classes:"vin",
    // legend: tr(`VinLegend`,lang),
    legend: "VIN",
    type: `text`,
    val: car?.VIN ? sanitizeTxt(car.VIN, `VIN`).sanText : '',
    err: editErr?.carVIN ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, VIN:sanitizeTxt(val, `VIN`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, carVIN:sanitizeTxt(val, `VIN`).sanErr}))
  },
  programmer:{
    legend: "Programmer",
    type: `text`,
    plhol: "Programmer",
    groups: programmers,
    val: car?.programmer ?? '',
    err: editErr?.programmer ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, programmer:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, programmer:sanitizeTxt(val, `default`).sanErr}))
  },
  swType:{
    legend: "Software Type",
    type: `text`,
    plhol: "Software Type",
    groups: swTypes,
    val: car?.swType ?? '',
    err: editErr?.swType ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, swType:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, swType:sanitizeTxt(val, `default`).sanErr}))
  },
  readMethod:{
    legend: "Read Method",
    type: `text`,
    plhol: "Read Method",
    groups: readMethods,
    val: car?.readMethod ?? '',
    err: editErr?.readMethod ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, readMethod:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, readMethod:sanitizeTxt(val, `default`).sanErr}))
  },
  mod:{
    legend: "Modifications",
    type: `text`,
    plhol: "Modifications",
    groups: modTypes,
    val: car?.mod ?? '',
    err: editErr?.mod ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, mod:sanitizeTxt(val, `default`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, mod:sanitizeTxt(val, `default`).sanErr}))
  },
  price:{
    classes:"Price",
    legend: "Price",
    type: `text`,
    val: car?.price ? sanitizeTxt(car.price, `price`).sanText : '0.00',
    err: editErr?.price ?? '',
    cbVal: (val)=>{
      setCar( (prev)=> ({...prev, price:sanitizeTxt(val, `price`).sanText}))
      setSave(true)
    },
    cbErr: (val)=> setEditErr( (prev)=> ({...prev, price:sanitizeTxt(val, `price`).sanErr}))
  }
})