import React from "react"

import { brandIco } from "./StoreSoftwareReducer"
import { CarsWrapper } from "./CarsWrapper"


export function ModelsWrapper({ props:{models, cars, Reducer} }) {

  return (
    <div className="ModelsWrapper flex stretch start">

      <div className="ModelsColumn flex column start">

        <div className="ModelsTopLine flex start">
          <img className="ImgBtn IcoColor flex" src={brandIco(models[0]?.brand)} alt="SVG" />
          <span className="BrandName flex bold">{models[0]?.brand}</span>
        </div>
        
        {
          models.map( (item, i)=>{

            const classes = `Model ${item?.model === cars?.model ? `ActiveModel` : ``} flex between`

            return(
              <div className={classes} onClick={()=>Reducer({type:"SELECT_MODEL", model:item?.model})} key={`Model${i}`}>
                <span>{item?.model}</span>
                <div className="Count flex">{item?.count}</div>
              </div>
            )
          })
        }

      </div>

      { cars && <CarsWrapper props={{cars, Reducer}} /> }

    </div>
  )
}