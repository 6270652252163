import React from "react"

import "./Electronics.scss"


function Electronics() {
  return (
    <div className="Electronics flex">Electronics</div>
  )
}

export default Electronics