import React from 'react'

import InputText from '../../../../All/InputText'


export function CardBtns({ props:{id, lang, edit, price, Reducer} }) {

  return (
    <div className="CardBtns flex end">

      { !edit && <div className="BuyBtn flex" onClick={()=>Reducer({type:"BUY_SOFTWARE", id, lang})} >Buy Now</div> }


      <div className="Price flex end">
      {
        !edit
        ?
        <span className='PriceBtn flex bold'>{`${price?.val} zł`}</span>
        :
        <InputText props={ price }/>
      }
      </div>

    </div>
  )
}
