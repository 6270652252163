import React from 'react'

import ActionBtn from '../../../../All/ActionBtn'
import { brandIco } from './../StoreSoftwareReducer'


export function CardTopLine({ props:{car, isAdmin, edit, save, Reducer} }) {

  const title = `
    ${car?.brand?.length ? car.brand : "Brand"} -
    ${car?.model?.length ? car.model :  "Model"} /
    ${car?.ECUType?.length ? car.ECUType :  "ECUType"} /
    ${car?.engine?.length ? car.engine :  "Engine"}
  `

  return (
    <div className="CardTopLine flex">

      <div className="Title flex start bold">
        <img className="IcoColor flex" src={brandIco(car?.brand)} alt="SVG" />
        <span>{title}</span>
      </div>

      <div className="CardID txtGry flex column end">
        <span>{car?._id ? `ID: ${car?._id}` : ""}</span>
      </div>

      {
        isAdmin &&
        <div className="AdminBtns flex end">

          { save && <ActionBtn props={{ name: `save`, click:()=>Reducer({type:"SAVE_CARD", car}) }} /> }

          <ActionBtn props={{ name: !edit ? `edit` : `cancel`, click:()=>Reducer({type:"CANCEL_EDITING_CARD"}) }} />

        </div>
      }

    </div>
  )
}
