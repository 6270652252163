import React from 'react'

import { bzBytesCalc } from '../../../../../AppFunctions'
import { FileUpload } from './FileUpload'


export function SoftwarePannel({ props:{id, car, edit, setCar, setSave} }) {

  const link = 'https://bzdrive.com'
  const defaultFileAddr = `files/SoftwareStore/${id}`

  function SET_SOFTWARE_FILES(res){
    setCar(prev=> ({...prev, software: prev?.software?.length ? [...prev?.software, res?.data] : [res?.data]}))
    setSave(true)
  }

  return (
    <div className="SoftwarePannel flex column start">

      <div className="SoftWareTopLine flex start bold">
        Pliki
      </div>

      {
        car?.software?.length &&
        <div className="SoftWareLines flex column start">
        {
          car?.software.map( (soft, s)=>{

            const key = `SoftWareLine${s}`
            const size = `${bzBytesCalc(soft?.size)?.num} ${bzBytesCalc(soft?.size)?.unit}`
            const href = `${link}/${defaultFileAddr}/${soft?.name}`

            return(
              <div className="SoftwareLine flex" key={key}>
                <a className="FileName flex start" href={href} target="_blank" rel="noopener noreferrer">
                  {soft?.name}
                </a>
                <span className="FileSize flex end">{size}</span>
              </div>
            )
          })
        }
        </div>
      }
      
      { edit && id !== "new" && <FileUpload props={{defaultFileAddr, cb:SET_SOFTWARE_FILES}} /> }
      
    </div>
  )
}
